
.app {
    background-color: #363636;
}

::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #e7e7e7;
    // background-color: #363636;
    border: 1px solid #cacaca;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #363636;
    // background-color: #e7e7e7;
}

.body_overflow {
    overflow-y: auto !important;
}
.body_overflow_hidden {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}

@keyframes highlight {
    0% { background-color: yellow; }
    100% { background-color: transparent; }
}