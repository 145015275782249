body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.maintenance-banner {
  width: 100%;
  background-color: #ffff23;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  position: fixed;
  top: 0;
  z-index: 1000;
  font-size: 10px;

}

.maintenance-banner__icon {
  margin-right: 10px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
