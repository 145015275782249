.admin_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
  // height: 100px;
  position: relative;
  
  .admin_header_logo_wraper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .admin_header_logo {
    }
  }
}

.back_to_admin {
  white-space: nowrap;
  padding: 8px; 
  background-color: red;
  cursor: pointer;
  border-radius: 4px;
  color: white;

  &:hover {
    background-color: rgb(177, 24, 24);
    color: rgb(168, 158, 158);
    transition: 0.3s ;
  }
}
