
.messages__mobile {
    &__container {
        display: none;
        margin-top: 10px;
        width: 100%;

        @media screen and (max-width: 800px) {
            & {
                display: block;
            }
        }
    }

    &__content {
        border-bottom: 1;
        border-color: 'gray';
        display: flex;
        justify-content: space-between;
       
    
        @media screen and (max-width: 800px) {
            & {
                position: absolute;
                bottom: 40px;
                left: 0; 
                right: 0;
            }
        }
    }
    
    .message__close {
    
        // @media screen and (max-width: 800px) {
        //     & {
        //         // display: none !important;
        //     }
        // }
    }
}

.messages__desktop {

    &__container {
        display: none;

        @media screen and (min-width: 800px) {
            & {
                margin-top: 20px;
                display: flex;
                width: 100%;
            }
        }
    }

    &__content {
        margin-left: 10px;
        width: 60%;
        // display: flex;
        // flex-direction: column;
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
    }
}

.body_overflow_y_hidden {
    overflow-y: hidden !important;
}

.chat {
    height: 100%;
    position: relative;

    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &__messages {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: calc(100% - 50px);
        padding: 0 8px;
    }

    &__message {
        word-break: break-all;
        display: flex; 
        align-self: flex-start;
        max-width: 70%;
        margin-top: 8px;
        flex-direction: column;

        padding: 8px;
        border-radius: 4px;
    }

    &__message-my {
        word-break: break-all;
        display: flex; 
        align-self: flex-end;
        max-width: 70%;
        margin-top: 8px;
        flex-direction: column;

        padding: 8px;
        border-radius: 4px;
    }

    &__bottom {
        position: absolute;
        bottom: -50px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
    }

    @media screen and (max-width: 600px) {
        &__messages {
            height: calc(100% - 60px);;
        }
        &__bottom {
            bottom: -60px;
        }
    }
    @media screen and (max-width: 370px) {
        & {
            height: calc(100% - 30px);
        }
    }
}

.list_show_archive {
    display: flex;
    gap: 4px;
    align-items: center;
    flex-direction: row;

    @media screen and (max-width: 1000px) {
        & {
            flex-direction: column;
        }
    }
}

.list_items {
    overflow-y: auto;
    margin-top: 10px;
    padding-right: 8px;
    bottom: 20px;
    left: 0;
    right: 0;
    top: 270px;
    position: absolute;
}

.send_menu {
    top: -60px !important;

    @media screen and (max-width: 800px) {
        & {
            top: -120px !important
        }
    }
}

@media screen and (max-width: 800px) {
    .list_items {
        bottom: -60px;
    }
}
